@import '../../theme/vars';

.item_purchase{
  background: #ffffff;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.10);
  margin-top: .5rem;
  position: relative;
  .btn_delete_group{
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 9;
    color: #aaaaaa;
    font-size: .70rem;
    cursor: pointer;
    &:hover{
      color: red;
    }
  }
  .card{
    border: 0;
    border-radius: 0;
  }
  .card-header{
    background: transparent;
    padding: .9rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_logo_shop{
      padding-right: .75rem;
      .bg_logo_shop{
        width: 55px;
        height: 55px;
        background: #ffffff;
      }
    } 
    .to_shop{
      display: inline;
      margin: 0;
      font-size: .80rem;
      padding-top: .25rem;
      &>a{
        color: $primary !important;
      }
      &:hover{
        .fas {
          padding-left: .25rem;
        }
      }
    }
    .lastdata{
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      .ico_chevron{
        cursor: pointer;
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .80rem;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        border: 1px solid #cccccc;
        color: #666666;
        -webkit-transition: .5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        transition: .5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        &.open{
          transform: rotate(-180deg);
        }
        .fa-chevron-down{
          padding-top: .25rem;
        }
      }
    }
  }
  .card-body{
    padding: 0 ;
  }
}
