@import '../../theme/vars';

.box_item_purchase_details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .90rem;
  margin: .75rem 0;
  padding: .5em .75rem;
  &:hover{
    background: $bg;
  }
  .btn_delete_item{
    padding-right: .75rem;
    cursor: pointer;
    color: #aaaaaa;
    &:hover{
      color: $primary;
    }
  }
  .item_details{
    *{
      margin:0
    }
    .price_unit{
      font-weight: 300;
      color: #999999;
      padding-top: .15rem;
      font-size: .80rem;
    }
  }
  .box_catidad{
    min-width: 60px;
    margin-left: 1rem;
  }
  .box_item_total{
    padding-left: .75rem;
  }
}