.wrapper_coupones_slider {
    .ttls_hub p {
        padding-right: 5rem;
    }

    .item_coupon {
        .item_content_coupon {
            margin: 0 .5rem;
            position: relative;

            img {
                border-radius: .75rem
            }

            &:hover .hover_content_coupon {
                top: 0;

                .content_coup {
                    .boxbtndown, .boxbtnlink {
                        opacity: 1;
                        bottom: 0;
                    }
                }
            }

            .hover_content_coupon {
                -webkit-transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
                transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
                background: rgba(0,0,0,.65);
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                color: #ffffff;
                padding: .7rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: .75rem;

                .content_coup {
                    border: 3px solid #fff;
                    border-radius: .75rem;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    flex-direction: column;

                    .boxbtndown {
                        opacity: 0;
                        transition-delay: 0.35s;
                        position: relative;
                        bottom: -40px;
                        -webkit-transition: 0.65s cubic-bezier(0.2, 0.61, 0.41, 0.95);
                        transition: 0.65s cubic-bezier(0.2, 0.61, 0.41, 0.95);
                    }

                    .boxbtnlink {
                        opacity: 0;
                        transition-delay: 0.35s;
                        position: relative;
                        bottom: -40px;
                        -webkit-transition: 0.65s cubic-bezier(0.2, 0.61, 0.41, 0.95);
                        transition: 0.65s cubic-bezier(0.2, 0.61, 0.41, 0.95);

                        .link_more {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .BrainhubCarousel__arrows {
        background: transparent;
        padding: 21px 15px;
        outline: none;

        &:hover {
            background: transparent;

            span {
                border-color: red;
            }
        }

        span {
            border-color: #000;
            border-width: 5px 5px 0 0;
        }
    }

    .dwr_coupones_slider {
        position: relative;

        .slick-slide > div {
            line-height: 0;
        }
        /* Arrows */
        .slick-prev,
        .slick-next {
            font-size: 0;
            line-height: 0;
            background: #dfe7ef;
            position: absolute;
            top: -65px;
            right: 0;
            display: block;
            width: 30px;
            height: 30px;
            padding: 0;
            z-index: 9;
            cursor: pointer;
            color: transparent;
            border: none;
            outline: none;
            border-radius: 0;
            -webkit-transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
            transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);

            &:hover {
                background: #dfe7ef;
            }
        }

        .slick-prev {
            right: 35px;

            &:hover:before {
                border-right: 8px solid red;
            }
        }

        .slick-next {

            &:hover:before {
                border-left: 8px solid red;
            }
        }

        .slick-prev:hover,
        .slick-prev:focus,
        .slick-next:hover,
        .slick-next:focus {
            outline: none;
        }

        .slick-prev:before,
        .slick-next:before {
            content: "";
            position: absolute;
            top: calc(50% - 6px);
            left: calc(50% - 4px);
            z-index: 9;
        }

        .slick-prev:before {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-right: 8px solid #999999;
            border-bottom: 7px solid transparent;
        }

        .slick-next:before {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-left: 8px solid #999999;
            border-bottom: 7px solid transparent;
        }
    }
}
.modal.show .myModalCoup {
    transform: none;
    position: fixed;
    bottom: 0;
    left: calc(50% - 450px);
    width: 100%;
    margin-bottom: 0;

    .closeModal {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        font-size: 1.25rem;
        z-index: 9;
    }

    .modal-content {
        border-radius: 1rem 1rem 0 0;

        .modal-body {
            padding: 2rem;

            .img_coup_details img {
                border-radius: 1rem;
            }
        }

        .p_desc_coup{
            font-size:.90rem;
        }
    }
}



@media (min-width: 576px) {
    .myModalCoup.modal-dialog {
        max-width: 900px;
    }
}