@import "../../theme/vars";

.wrapper_footer {
    background: #ffffff;
    text-align: center;
    font-weight: 300;
    font-size: 0.85rem;
    border-top: 1px solid #e5edef;
    margin-top: 1rem;

    p {
        font-size: 14px;
    }

    h6 {
        font-size: 0.9rem;
    }

    .box_sociales {
        padding: 1rem 1rem 0;

        .fab {
            font-size: 1.3rem;
            padding: 0 0.25rem;
            color: $primary;
        }
    }

    .box_legal {
        border-left: 1px solid #e5edef;
        border-right: 1px solid #e5edef;
        padding: 1rem 1rem 0;
    }

    .box_contacto {
        padding: 1rem 1rem 0;
    }

    .pie {
        border-top: 1px solid #e5edef;
        padding: 0.45rem 0;
    }
}
