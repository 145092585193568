@import "../../theme/vars";

.contain_providers {
    width: calc(100% - 270px);
    margin-left: 20px;

    .contain_info_provaider {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .box_info_provaider {
            color: #ffffff;
            text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);

            .ttls_provider {
                font-family: $font_ttls;
                font-weight: 600;
            }

            .info_provider {
                font-weight: 300;
                max-width: 475px;
                font-size: 0.95rem;
                padding-right: 1.25rem;
            }
        }

        .box_logo_provaider {
            min-width: 150px;
            text-align: right;
            margin-bottom: 1rem;

            & > img {
                height: 50px;
            }
        }
    }

    .wrapper_products_provider {
        background: #ffffff;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;

        .main_ttls_prov {
            .row {
                .btns {
                    text-align: right;
                }
            }
        }

        .wrapper_categ_mobile {
            display: none;
        }

        .content_products_provider {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

.modal_availability {
    position: relative;
    padding: 1rem 0;

    .btn_closemodal {
        cursor: pointer;
        border: 1px solid #dce3e8 !important;
        background: #ffffff;
        width: 30px;
        height: 30px;
        position: absolute;
        padding-top: 5px;
        top: -25px;
        right: 0px;
        border-radius: .4rem;
        text-align: center;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    }

    .wrp_ttls {
        h1 {
            font-size: 2.25rem;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 0;
        }

        .paragraph {
            font-weight: 300;
            font-size: 0.95rem;
            margin-top: 0.5rem;
        }
    }

    .wpr_accordion {
        padding: .25rem 2rem 1.25rem;
        height: 90vh;
        overflow: auto;
    }

    .accordion {
        .card {
            margin-bottom: 0.75rem;
            border: 1px solid #dce3e8 !important;
            border-radius: 0.4rem !important;

            .card-header {
                cursor: pointer;
                padding: 0.75rem;
                font-family: $font_ttls;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 1.25rem;
                background: #f0f5fa;
                border-bottom: 1px solid #dce3e8 !important;

                &:hover {
                    background: #eaeff3;
                }
            }

            .card-body {
                ul {
                    margin-bottom: 0;
                    padding-left: 1rem;

                    li {
                        margin-bottom: 0.25rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
    
    
  
  

