@import "../../theme/vars";

.wrapper_header {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;

    .box_head_top {
        font-size: 0.8rem;
        padding: 0.3rem 0;
        border-bottom: 1px solid #dddddd;

        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                color: $black;

                &:hover {
                    color: $primary !important;
                }
            }

            .content_one {
                & > span {
                    margin-right: 0.75rem;

                    .fas {
                        margin-right: 0.25rem;
                    }
                }
            }

            .content_two {
                .fab {
                    font-size: 1rem;
                    padding-left: 0.25rem;
                    color: $primary;
                }
            }
        }
    }

    .box_head_content {
        .box_brand_logo {
            min-width: 120px;

            img {
                height: 52px;
            }
        }

        .box_brand_logo_company {
            min-width: 125px;

            img {
                height: 35px;
            }
        }

        .box_content_options {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .content_opt_a {
                margin-left: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .item_link_menu {
                    cursor: pointer;

                    .name_options {
                        display: flex;
                        font-size: 0.85rem;
                        color: #666666;
                        height: 55px;
                        font-weight: 300;
                        justify-content: center;
                        align-items: center;
                        padding: 0 0.5rem;

                        &:active,
                        &:focus,
                        &:hover {
                            color: $black;
                            background: $bg;
                        }

                        &::after {
                            display: inline-block;
                            margin-left: 0.255em;
                            vertical-align: 0.255em;
                            content: "";
                            border-top: 0.3em solid rgba(0, 0, 0, 0.25);
                            border-right: 0.3em solid transparent;
                            border-bottom: 0;
                            border-left: 0.3em solid transparent;
                        }

                        .span_name_op {
                            position: relative;

                            &.news {
                                &::before {
                                    content: "NUEVO";
                                    position: absolute;
                                    top: -12px;
                                    left: 0;
                                    font-weight: 500;
                                    font-size: 0.5rem;
                                    background: $primary;
                                    border-radius: 0.15rem;
                                    color: #eeeeee;
                                    padding: 0.05rem 0.17rem 0.05rem;
                                }
                            }
                        }
                    }
                }
            }

            .content_opt_b {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & .logeado {
                    display: inline-block;
                    vertical-align: middle;
                    border-left: 1px solid #ddd;
                    cursor: pointer;
                    position: relative;
                    padding: 0;

                    .box_user_logeado {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0 0.5rem;
                        padding-top: 0.6rem;
                        .hello_user {
                            color: $primary;
                            margin: 0;
                            font-size: 0.8rem;
                            font-weight: 300;
                        }

                        .name_user {
                            margin-bottom: 0;
                            position: relative;
                            padding-right: 0.75rem;
                            font-size: 0.9rem;

                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid rgba(0, 0, 0, 0.25);
                                position: absolute;
                                top: 8px;
                                right: 0;
                            }
                        }
                    }

                    .dropdown-menu {
                        position: absolute;
                        top: 15px !important;
                        border-radius: 0;
                        border: 0;
                        width: 100%;
                        padding: 0;
                        background: #333333;
                        margin: 0;

                        .dropdown-item {
                            font-weight: 400;
                            font-size: 0.9rem;
                            padding: 0.75rem !important;
                            color: #ffffff;

                            &:hover,
                            &:focus,
                            &:active {
                                background: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    .ico_user {
        img {
            height: 23px;
            margin-right: 0.3rem;
        }
    }

    .drop_menu {
        border-radius: 0;
        padding: 0;
        background: #333333;
        margin: 0;
        border: 0;

        .dropdown-item {
            font-weight: 400;
            font-size: 0.9rem;
            padding: 0.75rem !important;
            color: #ffffff;

            &:hover,
            &:focus,
            &:active {
                background: $primary;
            }
        }
    }

    .link_options {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        height: 55px;
        flex-direction: column;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 300;
            margin: 0;
        }

        &:hover {
            color: $black;
            background: $bg;
        }

        &.box_user {
            flex-direction: row;
            border-left: 1px solid #ddd;

            .content_user {
                padding-right: 0.5rem;

                .ttl_user {
                    margin: 0;
                    font-size: 1rem;
                    font-weight: 600;
                }

                .go {
                    margin: 0;
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: $primary;
                }
            }
        }

        &.active_purchase {
            background: $primary;
            position: relative;
            color: #ffffff;

            .ico_canasta {
                position: relative;

                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    top: -4px;
                    right: -8px;
                    background: #ffffff;
                    border-radius: 50%;
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .box_options_mobile {
        display: none;
    }
}


.wrapper_logeo {
    position: relative;
    max-width: 770px !important;

    .modal-content {
        background: transparent !important;
        box-shadow: none;
        border: 0;

        .btn_close_modal {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 1;
            font-family: $font_ttls;
            font-weight: 500;
            font-size: 0.65rem;
            border: 2px solid $primary;
            padding: 0.15rem 0.25rem;
            color: $primary;
            border-radius: 0.25rem;
            cursor: pointer;

            &:hover {
                background: $primary;
                color: #ffffff;
            }
        }

        .box_content_logeo {
            display: flex;

            .content_info {
                min-height: 500px;
                background: url(/imgs/bglogin.jpg) no-repeat top center;
                background-size: cover;
                color: #ffffff;
                position: relative;
                padding: 1.5rem 1rem;
                width: 75%;
                text-align: center;
                text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                &::before {
                    content: "";
                    background: rgba(233, 66, 66, 0.8);
                    background: -moz-linear-gradient(top, rgba(233, 66, 66, 0.8) 0%, rgba(93, 3, 1, 0.9) 100%);
                    background: -webkit-gradient( left top, left bottom, color-stop(0%, rgba(233, 66, 66, 0.8)), color-stop(100%, rgba(93, 3, 1, 0.9)) );
                    background: -webkit-linear-gradient(top, rgba(233, 66, 66, 0.8) 0%, rgba(93, 3, 1, 0.9) 100%);
                    background: -o-linear-gradient(top, rgba(233, 66, 66, 0.8) 0%, rgba(93, 3, 1, 0.9) 100%);
                    background: -ms-linear-gradient(top, rgba(233, 66, 66, 0.8) 0%, rgba(93, 3, 1, 0.9) 100%);
                    background: linear-gradient(to bottom, rgba(233, 66, 66, 0.8) 0%, rgba(93, 3, 1, 0.9) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e94242', endColorstr='#5d0301', GradientType=0 );
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                & > * {
                    position: relative;
                    z-index: 1;
                }

                &.login {
                    max-height: 400px;
                }
            }

            .content_logeo {
                background: #ffffff;
                height: auto;
                padding: 1rem 1.5rem;
                width: 100%;
                position: relative;

                .hide {
                    display: none;
                }

                &.login {
                    .htop {
                       max-width: 310px;
                       margin: 0 auto;
                    }
                }

                .htop {
                    padding-top: 10%;
                }

                .custom-control-inline {
                    display: inline-flex;
                    margin-right: 0.45rem;
                }
            }
        }
    }
}
