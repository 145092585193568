// FONTS SYSTEMS ---
$font_ttls: 'Poppins', sans-serif;
$font_text: 'Roboto', sans-serif;

// COLOR SYSTEMS ---
$bg: #F0F5FA;
$black: #333333;

$primary: #E94242;
$primary_hover: #c02e2e;

@mixin scrollbar_horizontal {
    /* height */
    &::-webkit-scrollbar {
        height: 8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #eeeeee;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #dddddd;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #aaaaaa;
    }
}