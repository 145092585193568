.hidden {
    display: none;
}

.wrapper-banorte {
    margin-top: 8rem !important;

    h1 {
        color: #646468;
        font-size: 1.5em;
        font-weight: 600;
    }

    input.error {
        border: 1px solid red !important;
    }

    small {
        margin-left: 10px;
        color: red;
        font-style: italic;
        display: block;
    }

    .data-side {
        .data-content {
            width: 100%;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 1px 1px 12px rgba(0, 0, 0, .1);
            text-align: left;
            padding: 1.25rem;
            margin-bottom: 1.75rem;
            label {
                color: #2273c1;
                font-weight: bold;
                margin-bottom: .25rem;
            }

            input {
                background: #eff3f7 !important;
                border: none !important;
                width: 100% !important;
                padding: 0.80rem !important;
                font-size: 1rem !important;
                font-family: 'Ubuntu', sans-serif !important;
                margin-bottom: 1.25rem;
                border-radius: 10px !important;
                color: #646468;
                outline: none;
            }

            .data-p {
                color: #646468;
                font-weight: bold;
                margin-bottom: .25rem;
            }

            .amount-p {
                font-size: 25px;
                color: #2273c1;
                font-weight: bold;
                margin-bottom: .25rem;
            }
        }
    }

    .payment-side {

        .payment-content {
            width: 100%;
            background: #ffffff;
            border-radius: 10px;
            text-align: left;
            padding: 1.25rem;
            box-shadow: 1px 1px 12px rgba(0, 0, 0, .1);

            .cancel-button-content {
                button {
                    color: #ff0000 !important;
                    margin-top: 1rem !important;
                    width: 100% !important;
                    background: white !important;
                    padding: 0.6rem 1rem !important;
                    border: 1.2px solid #ff0000 !important;
                    border-radius: 5px;
                    font-family: "Ubuntu", sans-serif;
                    font-size: 1rem;
                    font-weight: bold;

                    &:hover {
                        color: white !important;
                        background: #ff0000 !important;
                    }
                }
            }

            p {
                color: #2273c1;
                margin-bottom: .30rem;
                font-weight: bold;
            }


            .cardShow-label {
                color: #646468;
            }

            .payment-card, .payment-date, .payment-code {
                background: #eff3f7 !important;
                border: none !important;
                width: 100% !important;
                padding: 0.80rem !important;
                font-size: 1rem !important;
                font-family: 'Ubuntu', sans-serif !important;
                margin-bottom: 1.25rem;
                border-radius: 10px !important;
                color: #646468;
                outline: none;
            }

            .card-i {
                font-size: 2rem;
                padding-top: 1rem;
            }

            .card-text {
                font-size: 10px;
                width: 100%;
                display: -webkit-inline-box;
                color: #646468;
            }

            button {
                color: white !important;
                margin-top: 1rem !important;
                width: 100% !important;
                padding: 0.6rem 1.5rem !important;
                border: none !important;
                border-radius: 5px !important;
                font-family: "Ubuntu", sans-serif !important;
                font-size: 1.2rem !important;
                background: #2273c1;
            }
        }

        .payment-img-content {
            width: 100%;

            img {
                height: 60px;
            }
        }
    }

    .information-side {
        .information-content {
            text-align: justify;
            width: 100%;
            padding: 0;
            margin-top: 1rem;

            .information-title {
                color: #646468;
                font-weight: bold;
                font-size: 15px;
            }

            .information-text {
                color: #646468;
                font-size: 13px;
            }
        }
    }
}

.pmail{
    word-break:break-all;
}
