@import "../../theme/vars";

.box_container_hub {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item_provaider_hub {
        width: 20%;
        height: 100%;
        padding: 0;

        & > .item__hub {
            -webkit-transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
            transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
            position: relative;

            &:hover {
                z-index: 1;
                box-shadow: 0 0 28px rgba(0,0,0,.85);

                & > img {
                    transform: scale(1.04)
                }
            }

            img {
                -webkit-transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
                transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
            }
        }
    }
}
