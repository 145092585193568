@import '../../theme/vars';

.aside_providers {
  background: #ffffff;
  top: 100px;
  width: 250px;
  height: 80vh;
  position: -webkit-sticky;
  position: sticky;
  padding: .5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  .wrapper_categories_provider{
    .list-group{
      .item_category{
        padding: .75rem ;
        font-family: $font_ttls;
        font-weight: 500;
        list-style: none;
        margin-bottom: .25rem;
        cursor: pointer;
        &:hover,
        &.active{
          background: #e8eef5;
          color: $primary;
        }
      }
    }
  }
}

