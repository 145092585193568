@import "../../theme/vars";

.wrapper_purchased_item {
  background: $bg;
  margin-bottom: 1rem;
  border: 1px solid #dce3e8;
  border-radius: 10px;
  .head_purchased_item {
    padding: 0.5rem;
    cursor: pointer;
    .first_data {
      display: flex;
      align-items: center;
      .ico_chevron {
        background: #ffffff;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        border: 1px solid #cccccc;
        color: #666666;
        -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
        &.open {
          transform: rotate(-180deg);
        }
      }
    }
    .ttl_info {
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
    .p_info {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 300;
      .paid {
        color: $primary;
        font-weight: 500;
      }
      .pending_payment {
        background: $primary;
        padding: 0.25rem 1rem;
        color: #ffffff;
        display: inline-block;
        border-radius: 0.25rem;
      }
    }
  }
  .body_purchased_item {
    border-top: 1px solid #cccccc;
    .info_item_shopping {
      .content_item_shopping {
        padding: .75rem 0;
        border-right: 1px solid #cccccc;
         border-top: 1px solid #cccccc;
            &:first-child{
              border-top: 0
            }
        .bg_logo_shop {
          background: #ffffff;
        }
        .item_purchased {
          font-weight: 300;
          font-size: 0.85rem;
          margin-bottom: 1.5rem;
          & > span:first-child,
          & > span:last-child {
            padding-right: 0.5rem;
            font-weight: 600;
          }
          & > span:last-child {
            padding-left: 0.75rem;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .action_item_shopping {
      text-align: center;
      padding: 1rem !important;
      & > p {
        margin: 0;
        line-height: 1;
        &:first-child {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
}
