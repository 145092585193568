
.item_card_register {
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 1px 1px 12px rgba(0,0,0,.10);
    background: #ffffff;
    position: relative;
    margin-bottom: 1rem;
    height: 100%;
    max-height: 130px;
    cursor: pointer;


    &:hover .ico_delete {
        display: block;
    }

    &.is_select {
        box-shadow: 0 0 0 4px #2273c1;
    }

    &.card_more {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        height: 100%;
        background: transparent;
        border: 2px solid #dedede;
        box-shadow: none;
        &:hover {
            background: #ffffff;
        }

        .icomore {
            font-size: 2.25rem;
            color: #cccccc;
        }
    }

    .ico_delete {
        display: none;
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 1.5rem;
        z-index: 9;
        cursor: pointer;
        color: #c8102c;
    }

    .content_item {
        position: relative;
        z-index: 1;
    }

    .spanico {
        position: absolute;
        top: -12px;
        right: 5px;
        font-size: 8rem;
        color: #f2f5f9;
    }

    .imgico {
        line-height: 0;
        margin-bottom: 0;
        img{
            height: 50px;
        }
    }

    .pvig {
        color: #2273c1;
        font-size: .9rem;
    }
}
