@import "../../theme/vars";

.wrapper_shoppingHistory {
    margin-bottom: 5%;

    .contain_shop_history {
        margin-top: 15rem;

        .ttls_sections_shop {
            color: #ffffff;
            font-weight: 600;
            font-family: $font_ttls;
            margin-bottom: 1.25rem;
        }

        .boxes_hop_history {
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            background: #ffffff;
            padding: 1rem;

            .paginated_page {
                text-align: end;

                button {
                    min-width: 36px;
                    border: 10px;
                    box-sizing: border-box;
                    display: inline-block;
                    font-family: Roboto, sans-serif;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    cursor: pointer;
                    text-decoration: none;
                    margin: 0px;
                    padding: 0px;
                    outline: none;
                    font-size: inherit;
                    font-weight: inherit;
                    position: relative;
                    height: 36px;
                    line-height: 36px;
                    min-width: 36px;
                    color: rgba(0, 0, 0, 0.87);
                    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
                    border-radius: 2px;
                    user-select: none;
                    overflow: hidden;
                    background-color: rgba(0, 0, 0, 0);
                    text-align: center;
                    border-radius: 45px;

                    &.isActive {
                        color: rgb(0, 188, 212) !important;
                    }

                    &:hover {
                        background-color: rgba(153, 153, 153, 0.2);
                    }
                }
            }
        }
    }
}
