.wrapper_slider_provider {
  position: relative;
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    background: #d4dae4;
    position: absolute;
    top: calc(50% - 5px);
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 9;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    opacity: 0.5;
  }

  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    opacity: 1;
    outline: none;
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 9;
  }

  .slick-prev:before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 8px solid #999999;
    border-bottom: 5px solid transparent;
  }

  .slick-next:before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 8px solid #999999;
    border-bottom: 5px solid transparent;
  }
}
