@import "../../theme/vars";

.box_video_provider {
  position: relative;
  & > img {
    border-radius: 0.5rem;
  }
  .ico_play {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #ffffff;
    z-index: 1;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  }
  &:after {
    content: "";
    height: 100%;
    border-radius: 0.5rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

.wrapper_modal_iframe {
  .modal-content {
    background: transparent;
    padding-top: 5%;
    border: 0;
    .content_iframe_video {
      position: relative;
      .btn_close_modal {
        position: absolute;
        top: -25px;
        right: 0;
        z-index: 1;
        font-family: $font_ttls;
        font-weight: 500;
        font-size: 0.65rem;
        background: $primary;
        border: 2px solid $primary;
        padding: 0.15rem 0.25rem;
        color: #ffffff;
        border-radius: 0.25rem;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        &:hover {
          background: $primary_hover;
          border: 2px solid $primary;
        }
      }
      .iframe_video {
        & > iframe {
          width: 100% !important;
          height: 100% !important;
          min-height: 375px;
          border-radius: .5rem;
        }
      }
    }
  }
}
