@import "../../theme/vars";

.wrapper_providers {
  margin-top: 220px;
  .bg_providers {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    position: absolute;
    top: 80px;
    left: 0;
    min-height: 300px;
    width: 100%;
    z-index: -1;
  }
  .box_contain_providers {
    & > div {
      display: inline-block;
      vertical-align: top;
    }
  }
}
