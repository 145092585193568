@import "../../theme/vars";

.wrapper_aside_purchase {
    background: $bg;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    max-width: 470px;
    z-index: 999;
    overflow: auto;
    margin-right: -505px;

    &.is-show {
        margin-right: 0;

        & ~ .bg_pleca {
            display: block;
        }
    }

    .btn_closeaside {
        background: $primary;
        border: 2px solid $primary;
        padding: 0.15rem 0.4rem;
        color: #ffffff;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        cursor: pointer;

        &:hover {
            background: $primary_hover;
            border: 2px solid $primary;
        }
    }

    .box_content_aside {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .titulares {
            text-align: center;
            padding: 0.8rem;
            background: #ffffff;
            justify-content: space-between;
            align-items: center;
            min-height: 70px;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
            z-index: 1;

            .btn.rb_go_brd {
                padding: 0.3rem 0.75rem;
                min-width: 0;
            }
        }

        .content_items {
            max-height: calc(100% - 150px);
            overflow: auto;
            padding-bottom: 1rem;
            flex-grow: 1;
            /* width */
            &::-webkit-scrollbar {
                width: 7px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: $bg;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

    .box_footer_aside {
        background: #ffffff;
        box-shadow: 0px -3px 7px rgba(0, 0, 0, 0.15);
        min-height: 210px;
        padding: 0.6rem;
        padding-bottom: 0;
        z-index: 9;

        .box_total_neto {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.25rem;

            .label {
                font-size: 0.85rem;
            }

            .box_w {
                width: 90%;
                text-align: right;
            }
        }
    }
}

.bg_pleca {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    z-index: 99;
}

.wrapper_paymethod {
    .ttls_sections {
        text-align: center;
        position: relative;

        &::after {
            content: "";
            height: 1px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            background: #dddddd;
        }

        & > span {
            position: relative;
            z-index: 1;
            background: #ffffff;
            padding: 0 1rem;
        }
    }

    .box_powered_by {
        text-align: center;

        img {
            height: 35px;
        }
    }
}
