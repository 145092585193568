@import "../../theme/vars";

.wrapper_favs_check {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 1;

    .content_favs_check {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        text-align: right;
        color: #ffffff;
        display: flex;
        justify-content: flex-end;
        padding: 0 1.5rem;

        .boxes_favs {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            align-items: center;

            .box_label_favs {
                font-family: $font_ttls;
                font-weight: 300;
                font-size: 0.8rem;
            }

            .box_ico_favs {
                background: #ffffff;
                padding: 0.2rem 0.3rem;
                color: $primary;
                margin-left: 0.35rem;
                font-size: 0.9rem;
                border-radius: 0.25rem;
            }
        }
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(slide-down) {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.65);
        transform: scale(1.65);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.btn_animated {
    @include animation("slide-down .5s 3");
}
