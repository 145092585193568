.box_modal_ico {
    text-align: center;
    font-size: 5rem;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1.15;

    .ico_success {
        color: #279b27;
    }
}

.modal-sm {
    width: 300px !important;
}
