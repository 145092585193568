@import '../../theme/vars';

.wrapper_bullets {
    margin-bottom: 3rem;

    img {
        height: 100px;
    }

    .box_item_bullets {
        margin: 2.5rem 0;

        img {
            height: 90px;
        }

        h5 {
            font-family: $font_ttls;
            line-height: 1;
            margin-top: .75rem;
            font-size: 1.15rem;
            font-weight: 600;
        }

        p {
            font-size: .90rem;
            font-style: italic;
            font-weight: 300;
        }
    }
}
