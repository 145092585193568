.wrapper_provider_individual {
    margin-bottom: 3rem;

    .item_provider_individual {
        img {
            border-radius: .75rem;
            width: 100%;
        }
    }
}
