@import "../../theme/vars";

.wrapper_itemPromoHome {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin-bottom: 5rem;
  .box_info {
    .box_info_content {
      font-family: $font_ttls;
      position: relative;
      margin-bottom: 2rem;
      &::before{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 125px;
        height: 7px;
        background: $primary;
      }
    }
    .paragraph {
      max-width: 450px;
      margin-bottom: 2rem;
    }
  }
}
